<template>
    <div class="surface-card p-6 border-1 surface-border">
        <update-view-header
            :headline="entity.id ? (entity.name + ' bearbeiten') : 'Unternehmen hinzufügen'"
            button-path="/companies"
            :button-text="$t('Back to overview')"
        />

        <form v-on:submit.prevent="submitEntity" v-if="editedEntity.id || $route.params.id === 'create'">
            <div class="field grid" v-for="(label, index) in fields" :key="index">
                <label class="col-fixed text-700 mt-1 w-4">
                    {{ label }}
                </label>
                <div class="col">
                    <InputText
                        class="w-full"
                        type="text"

                        v-model="editedEntity[index]"
                    />
                    <small class="p-invalid block mt-2" v-for="message in editedEntityErrors[index]" :key="message">
                        {{ message }}
                    </small>
                </div>
            </div>

            <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

            <div class="text-center">
                <Button type="submit" :label="$t('Save')"></Button>
            </div>
        </form>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext/InputText";
    import Button from "primevue/button";
    import UpdateView from "@/mixin/UpdateView";
    import UpdateViewHeader from "@/components/view/UpdateViewHeader";

    export default {
        mixins: [UpdateView],
        components: {
            UpdateViewHeader,
            InputText,
            Button,
        },
        data() {
            return {
                fields: {
                    'name': 'Name',
                    'location': 'Standort'
                }
            }
        },
        methods: {
            getApiEndpoint() {
                return 'companies';
            },
            getCreationAction(entity) {
                return this.$router.push('/companies/' + entity.id);
            },
        }
    }
</script>
